import {
  Container,
  Grid,
  Box,
  Group,
  Title,
  Stepper,
  Text,
} from "@mantine/core";
import { ArrowLeft } from "react-feather";

import Male from "../assets/male.svg";
import Female from "../assets/female.svg";

import LoginLayout from "../layouts/LoginLayout";
const Booking = () => (
  <LoginLayout>
    <Container size={"sm"} style={{ width: "100%" }}>
      <Group
        align={"center"}
        py={40}
        style={{ margin: "0 auto", justifyContent: "center" }}
      >
        <ArrowLeft />
        <Text>Back to home</Text>
      </Group>
      <Title order={2} align="center">
        Book HRA on call
      </Title>
      <Stepper color={"indigo"} iconSize={42} mb={24} active={1}>
        <Stepper.Step label="Basic details" />
        <Stepper.Step label="Schedule" />
      </Stepper>
      <Text align="center" mb={20}>
        Booking for
      </Text>
      <Grid gutter="xl">
        <Grid.Col
          span={6}
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[4]}`,
            borderRadius: theme.radius.md,
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            transition: "all 0.2s ease",
            "&:hover": {
              borderColor: theme.colors.gray[8],
            },
          })}
        >
          <Box>
            <img src={Male} height={40} width={40} />
            <Text size="lg" weight={"bolder"}>
              Rajesh kumar
            </Text>
            <Text size="sm">(42, self)</Text>
          </Box>
        </Grid.Col>
        <Grid.Col
          span={6}
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[4]}`,
            borderRadius: theme.radius.md,
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
            transition: "all 0.2s ease",
            "&:hover": {
              borderColor: theme.colors.gray[8],
            },
          })}
        >
          <Box>
            <img src={Female} height={40} width={40} />
            <Text size="lg" weight={"bolder"}>
              Rajesh kumar
            </Text>
            <Text size="sm">(42, self)</Text>
          </Box>
        </Grid.Col>
      </Grid>
    </Container>
  </LoginLayout>
);

export default Booking;
