import dayjs from "dayjs";
import {
  useMatch,
  useNavigate,
  Link,
  useSearch,
} from "@tanstack/react-location";
import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Group,
  Title,
  Stepper,
  Text,
  Button,
  Loader,
  MediaQuery,
  Modal,
} from "@mantine/core";
// import { Calendar, DatePicker } from "@mantine/dates";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { ArrowLeft, Clock, ChevronRight } from "react-feather";

import BookingLayout from "../layouts/BookingLayout";
import {
  useService,
  useServiceSlots,
  useServiceSlotsV2,
  useUserService,
} from "../services/services";
import { AvilableDays } from "../types/service";
import { GetStringSlot } from "../utils/helpers";
import { BookAppointmentRequest, PaymentRequest } from "../types/appointment";
import { createAppointment, createOrderId } from "../services/appointment";
import { GetProfile } from "../services/auth";
import clevertap from "clevertap-web-sdk";
import { CT } from "../constants";
import { BookLabTest, BookLabTestRC, GetRCSlotId } from "../services/labtest";
import { RcBookingRequest } from "../types/rc";
import { AxiosError, AxiosResponse } from "axios";
import "./calendar.css";

const Booking = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<number>();
  const [selectedSlotId, setSelectedSlotId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    params: { sid },
  } = useMatch();
  let { pid, aid, external, pincode, partner } = useSearch();
  if (pid == undefined) {
    pid = 0;
  }
  if (aid == undefined) {
    aid = 0;
  }
  const navigate = useNavigate();

  const { isLoading, status, data, error } = useUserService(
    parseInt(sid),
    pid !== 0,
    pid === 0 ? null : pid
  );
  const [unavailableDays, setUnavailableDays] = useState<number[]>([]);
  const [minDate, setMinDate] = useState<any>();
  const [maxDate, setMaxDate] = useState<any>();
  const [slots, setSlots] = useState<number[]>([]);
  const [bookingError, setBookingError] = useState("");
  const [paymentOrderId, setPaymentOrderId] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    if (paymentComplete) {
      navigate({
        to: "/confirmed-payment",
        replace: true,
        search: { orderId: paymentOrderId,type:data?.service?.type },
      });
      // handleBooking();
    }
  }, [paymentComplete]);

  const handleErrorTimer = () => {
    setTimeout(() => {
      setBookingError("");
    }, 8000);
  };

  useEffect(() => {
    if (!isLoading) {
      clevertap.event.push(CT.NAVIGO_OPEN_SCHEDULE_SLOT, {
        serviceID: data?.ID,
        serviceName: data?.name,
      });

      let ad = data?.service.availableDays.replaceAll("\\", "");
      let days: AvilableDays[] = JSON.parse(ad as string);
      let unavail: number[] = [];
      days?.map((d: AvilableDays, i: number) => {
        if (!d?.available) {
          unavail.push(i);
        }
      });
      setUnavailableDays(unavail);
      console.info(unavailableDays, days);
      const minDate = dayjs(new Date())
        .startOf("d")
        .add(data?.service?.minDayBooking as number, "d")
        .toDate();
      const maxDate = dayjs(new Date())
        .startOf("d")
        .add(data?.service.maxDayBooking as number, "d")
        .toDate();
     
      setMinDate(minDate);
      setMaxDate(maxDate);
     
    }
  }, [data]);
  const profile = GetProfile();
  const { data: slotData, isLoading: slotsLoading } = useServiceSlotsV2(
    parseInt(sid),
    dayjs(selectedDate).format("YYYY-MM-DD"),
    {
      enabled: !!(selectedDate !== null),
    },
    profile?.patientProfile?.id,
    pincode as number,
    "",
    "",
    aid as number
  );

  useEffect(() => {
    window.addEventListener("message", handleIframMessage);
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("message", handleIframMessage);
      }
    };
  }, []);

  const handleIframMessage = (e: any) => {
    if (
      e.origin === import.meta.env.VITE_ZYLA_PAY_URL &&
      e.data &&
      e.data.origin === "ZPAY"
    ) {
      console.log("data message recieved is ", e.data);
      if (e.data.status === "ok") {
        setShowPaymentModal(false);
        // handleBooking();
        setPaymentComplete(true);
      } else if (e.data.status === "cancelled") {
        setPaymentOrderId("");
        setShowPaymentModal(false);
      }
    }
  };

  const bookAppointment = async () => {
    setLoading(true);
    window.removeEventListener("message", handleIframMessage);
    if ((data?.service.discountedPrice as number) > 0 && paymentOrderId == "") {
      const patient = GetProfile();
      let orderIdRequest: PaymentRequest = {
        patientID: patient?.patientProfile?.id,
        bookedFor: parseInt(pid as string),
        serviceID: parseInt(sid),
        appointmentDate: dayjs(selectedDate)
          .add(5, "hours")
          .add(30, "minutes")
          .toISOString(),
        timeSlot: selectedSlot as number,
        timeSlotId: selectedSlotId as number,
        orderId: "",
        addressId: parseInt(aid as string),
        vendorId: slotData?.vendorId as string,
      };
      try {
        const orderData: AxiosResponse<PaymentRequest> = await createOrderId(
          orderIdRequest
        );
        console.log(orderData);
        console.log(orderData);
        setPaymentOrderId(orderData.data.orderId);
        setShowPaymentModal(true);
      } catch (error) {
        setBookingError(error?.response?.data?.message);
        handleErrorTimer();
      }
    } else {
      await handleBooking();
    }
    setLoading(false);
  };

  const handleBooking = async () => {
    setLoading(true);
    const patient = GetProfile();
    let appointment: BookAppointmentRequest = {
      patientID: patient?.patientProfile?.id,
      bookedFor: parseInt(pid as string),
      addressId: parseInt(aid as string),
      serviceID: parseInt(sid),
      bookingDate: new Date().toISOString(),
      appointmentDate: dayjs(selectedDate)
        .add(5, "hours")
        .add(30, "minutes")
        .toISOString(),
      status: 0,
      timeSlot: selectedSlot,
      timeSlotId: selectedSlotId,
      vendorId: slotData?.vendorId,
      paymentOrderId: paymentOrderId,
    };

    try {
      const ap = await createAppointment(appointment);

      let name =
        patient?.patientProfile?.firstName +
        " " +
        patient?.patientProfile?.lastName;
      let formatedAppointmentDate = dayjs(selectedDate).format("DD MMM YYYY");
      formatedAppointmentDate =
        GetStringSlot(selectedSlot as number) + "," + formatedAppointmentDate;

      // clevertap.event.push(CT.NAVIGO_BOOK_APPOINTMENT, {
      //   serviceID: data?.ID,
      //   serviceName: data?.name,
      //   appointmentDate: appointment.appointmentDate,
      //   appointmentSlot: appointment.timeSlot,
      // });

      navigate({
        to: "/confirmed",
        replace: true,
        search: { name: name, date: formatedAppointmentDate,type:data?.service?.type  },
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        try {
          let err = JSON.parse(error?.response?.data);
          let errMsg = JSON.parse(err.message);
          setBookingError(errMsg?.message);
        } catch {
          setBookingError(error?.response?.data);
        }
        handleErrorTimer();
      }
    }
    setLoading(false);
  };

  const tileDisabled = (date) => {
    return unavailableDays.includes(date.date.getDay());
  };

  const tileContent = ({ date, view }) => {
    // Customize the ID format as needed
    const dateId = `date_${date.getDate()}_${date.getMonth()}_${date.getFullYear()}`;

    return (
      <div id={dateId}>
        
      </div>
    );
  };

  return (
    <BookingLayout step={1} title={data?.service.name}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Modal
            // size={"xl"}
            withCloseButton={false}
            centered
            padding={0}
            opened={showPaymentModal}
            onClose={() => false}
          >
            <iframe
              style={{ border: "none" }}
              width={"100%"}
              height={"603px"}
              src={`${
                import.meta.env.VITE_ZYLA_PAY_URL
              }?orderId=${paymentOrderId}&origin=${window.origin}`}
            />
          </Modal>
          <Box
            p={20}
            sx={(theme) => ({
              color: theme.shadows.sm,
              border: `1px solid ${theme.colors.gray[3]}`,
              borderRadius: theme.radius.md,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "60vh",
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                alignItems: "flex-start",
              },
            })}
          >
            <Grid
              align="center"
              justify={"space-between"}
              style={{ height: "100%", width: "100%" }}
            >
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Grid.Col span={4}>
                  <Text size="lg" weight="bolder">
                    {data?.service.name}
                  </Text>
                  <Group spacing="xs">
                    <Clock height={16} width={16}></Clock>
                    <Text>{data?.service.duration} min</Text>
                  </Group>
                  <Text color="dark">{data?.service.description}</Text>
                </Grid.Col>
              </MediaQuery>
              <Grid.Col md={4} span={12}>
                <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <Calendar
                    value={selectedDate}
                    style={{ width: "100%" }}
                    onChange={setSelectedDate}
                    maxDetail="month"
                    minDetail="year"
                    tileDisabled={(date) => tileDisabled(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                    tileContent={tileContent}
                    //weekendDays={[]}
                    // titleDisabled = {({ date }) => isDateDisabled(date)}
                    // titleDisabled = {({date}) =>
                    //   unavailableDays.includes(date.getDay())
                    // }
                    // tileDisabled={(date) => {
                    //   console.log(date)
                    //   return unavailableDays.indexOf(date.getDay()) !== 0
                    // }
                    // }
                    // excludeDate={excludeDate
                    //   (date) =>
                    //   unavailableDays.indexOf(date.getDay()) !== -1
                    // }
                  ></Calendar>
                </MediaQuery>
                <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                  {/* <DatePicker
                    value={selectedDate}
                    placeholder="Select a date"
                    size="md"
                    width: "100%" }}
                    onChange={setSelectedDate}
                    weekendDays={[]}
                    excludeDate={(date) =>
                      unavailableDays.indexOf(date.getDay()) !== -1
                    }
                    minDate={minDate}
                    maxDate={maxDate}
                  ></DatePicker> */}
                  <Calendar
                    value={selectedDate}
                    style={{ width: "100%" }}
                    onChange={setSelectedDate}
                    maxDetail="month"
                    minDetail="year"
                    tileDisabled={(date) => tileDisabled(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                  ></Calendar>
                </MediaQuery>
              </Grid.Col>
              <Grid.Col md={4} span={12}>
                {selectedDate ? (
                  <>
                    <Text size="md" mb={20}>
                      {selectedDate
                        ? dayjs(selectedDate).format("dddd, MMM DD")
                        : ""}
                    </Text>
                    {slotsLoading ? (
                      <Loader></Loader>
                    ) : (
                      <div style={{ height: "320px", overflowY: "scroll" }}>
                        {slotData?.slots !== null &&
                          slotData?.slots.map((a) => (
                            <Slot
                              key={a.key}
                              time={GetStringSlot(a.value)}
                              selected={selectedSlot == a.value}
                              onClick={() => {
                                setSelectedSlot(a.value);
                                setSelectedSlotId(a.key);
                              }}
                            />
                          ))}
                        {slotData?.slots == null && (
                          <Text color={"dimmed"}>
                            No slots found for this day.
                          </Text>
                        )}
                      </div>
                    )}
                    {bookingError !== "" && (
                      <Box
                        py={8}
                        my={12}
                        sx={(theme) => ({
                          backgroundColor: theme.colors.red[6],
                          borderRadius: theme.radius.sm,
                        })}
                      >
                        <Text align="center" color={"white"}>
                          {bookingError}
                        </Text>
                      </Box>
                    )}
                    <Button
                      id="confirm_appointment"
                      fullWidth={true}
                      radius="sm"
                      size="lg"
                      disabled={!!!selectedSlot || loading}
                      onClick={() => bookAppointment()}
                    >
                      {loading && (
                        <Loader size={"sm"} mr="md" color={"white"} />
                      )}
                      <Group spacing="sm">
                        <Text>Confirm booking</Text>
                        <ChevronRight width={18} />
                      </Group>
                    </Button>
                  </>
                ) : (
                  <Title order={4}>Select a date to view time slots</Title>
                )}
              </Grid.Col>
            </Grid>
          </Box>
        </>
      )}
    </BookingLayout>
  );
};

const Slot = ({
  time = "10:00 am",
  selected = false,
  onClick,
}: {
  time?: string;
  selected?: boolean;
  onClick?: () => any;
}) => (
  <Button
    id={time}
    color="indigo"
    variant={selected ? "filled" : "outline"}
    fullWidth={true}
    my={8}
    onClick={onClick}
  >
    {time}
  </Button>
);

export default Booking;
