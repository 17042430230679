import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  ServiceResponse,
  Slots,
  SlotsV2,
  SlotsV2Request,
  UserServiceResponse,
  UserServicesResponse,
} from "../types/service";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { GetProfile } from "./auth";
import { GetRCSlots } from "./labtest";
import { getItem } from "./storage";

export const useServices = (args?: any): UseQueryResult<ServiceResponse[]> => {
  const user = getItem("USER_PROFILE");
  return useQuery<ServiceResponse[]>(
    ["services"],
    async function () {
      const { data } = await api.get<ServiceResponse[]>(
        ApiConfig.services.getServicesByPatientID.replace(
          "%d",
          user?.patientProfile?.id
        )
      );
      return data;
    },
    args
  );
};

export const useUserServices = (
  args?: any
): UseQueryResult<UserServicesResponse> => {
  const user = getItem("USER_PROFILE");
  return useQuery<UserServicesResponse>(
    ["services"],
    async function () {
      const { data } = await api.get<UserServicesResponse>(
        ApiConfig.services.getServicesByPatientID.replace(
          "%d",
          user?.patientProfile?.id
        )
      );
      return data;
    },
    args
  );
};

export const useService = (id: number): UseQueryResult<ServiceResponse> => {
  return useQuery<ServiceResponse>(["services", id], async function () {
    const { data } = await api.get<ServiceResponse>(
      ApiConfig.services.getService + id,
      {}
    );
    return data;
  });
};

export const useUserService = (
  id: number,
  isFamilyMember?: boolean,
  userId?: number
): UseQueryResult<UserServiceResponse> => {
  const user = getItem("USER_PROFILE");
  let pid = user?.patientProfile?.id;
  if (userId) {
    pid = userId;
  }
  return useQuery<UserServiceResponse>(
    ["services", id, pid],
    async function () {
      const { data } = await api.get<UserServiceResponse>(
        ApiConfig.services.getServiceByPatientID
          .replace("%d", id.toString())
          .replace("%p", pid),
        { params: { family: isFamilyMember ? "1" : "" } }
      );
      return data;
    }
  );
};

export const useServiceSlots = (
  id: number,
  date: string,
  args: any,
  pincode?: number | undefined,
  lab?: string | undefined,
  addressId?: number | undefined
): UseQueryResult<Slots> => {
  if (pincode) {
    // labtest slots
    if (lab == "Thyrocare") {
      const user = GetProfile();
      return useQuery<Slots, Error>(
        ["lab-testslots", { serviceId: id, date: date }],
        async function () {
          const { data } = await api.post<Slots>(
            ApiConfig.labtest.getSlots.replace("%d", user?.patientProfile?.id),
            {
              date: date,
              serviceId: id.toString(),
              pincode: pincode.toString(),
            }
          );
          return data;
        },
        args
      );
    } else {
      return useQuery<Slots, Error>(
        ["lab-testslots-rc", { serviceId: id, date: date }],
        async function () {
          const slots = await GetRCSlots({
            addressId: addressId as number,
            collectionDate: date,
            pincode: pincode,
            serviceId: id,
            vendor: "",
          });
          return slots;
        },
        args
      );
    }
  } else {
    return useQuery<Slots, Error>(
      ["slots", { serviceId: id, date: date }],
      async function () {
        const { data } = await api.get<Slots>(ApiConfig.services.getSlots, {
          params: {
            sid: id,
            date: date,
          },
        });
        return data;
      },
      args
    );
  }
};

export const useServiceSlotsV2 = (
  id: number,
  date: string,
  args: any,
  patientId: number,
  pincode?: number | undefined,
  lab?: string | undefined,
  vendorCode?: string | undefined,
  addressId?: number | undefined
): UseQueryResult<SlotsV2> => {
  return useQuery<SlotsV2, Error>(
    ["slots", { serviceId: id, date: date }],
    async function () {
      let req: SlotsV2Request = {
        addressId: addressId as number,
        collectionDate: date,
        email: "",
        patientId: patientId,
        pincode: pincode as number,
        serviceId: id,
        vendor: lab as string,
        vendorCode: vendorCode as string,
      };
      const { data } = await api.post<SlotsV2>(
        ApiConfig.services.getSlotsV2,
        req
      );
      return data;
    },
    args
  );
};
