import { useEffect, useState } from "react";
import {
  Grid,
  Title,
  TextInput,
  Button,
  Text,
  Group,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import Benefit from "../components/login/benefit";
import type { BenefitProps } from "../components/login/benefit";
import { Link, useNavigate } from "@tanstack/react-location";
import { GetOTP, VerifyOTP } from "../services/auth";
import LoginLayout from "../layouts/LoginLayout";

import { setItem } from "../services/storage";
import { User } from "../types/user";
import clevertap from "clevertap-web-sdk";
import { CT } from "../constants";

interface SignupForm {
  email: string;
  otp?: number;
}

const Signup = () => {
  const form = useForm<SignupForm>({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value: string) =>
        /^\S+@\S+$/.test(value) ? null : "Invalid email",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [OTP, setOTP] = useState<boolean>(false);
  const [time, setTime] = useState<number>(60);
  const [inter, setInter] = useState<number>();
  const [termsAgree, setTermsAgree] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    clevertap.event.push(CT.NAVIGO_SIGNUP);
  }, []);
  useEffect(() => {
    if (time == 0) {
      window.clearInterval(inter);
    }
  }, [time]);

  const handleSubmit = async (data: SignupForm) => {
    setLoading(true);
    let values;
    if (!OTP) {
      const interval = setInterval(() => {
        setTime((time) => {
          return time - 1;
        });
      }, 1000);
      setInter(interval);
      // Generate OTP
      try {
        values = await GetOTP({ emailid: data.email });
        if (values.status == 200) {
          setError("");
          setOTP(true);
        }
      } catch (error) {
        // @ts-ignore
        setError(
          "Organisation not registered, please check with your organisation."
        );
      }
    } else {
      // Handle OTP
      try {
        values = await VerifyOTP({
          emailid: data.email,
          // @ts-ignore
          otp: parseInt(data.otp),
        });
        if (values.status == 200) {
          // set the localstorage and navigate
          let user: User = {
            email: data.email,
            onboarded: false,
            user: null,
          };
          setItem("INITIAL_PROFILE", user);
          navigate({ to: "/onboarding", replace: true });
          setError("");
        }
      } catch (error) {
        // @ts-ignore
        setError(error?.response?.data?.message);
        //setError({error?.response?.data?.message});
      }
    }
    
    setLoading(false);
    
  };

  const handleCheckboxChange = (e) => {
    setTermsAgree(e.target.checked); 
  };

  return (
    <LoginLayout scrollHidden>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        })}
      >
        <Box
          sx={(theme) => ({
            maxWidth: theme.breakpoints.xs,
            width: "100%",
            boxShadow: theme.shadows.md,
            padding: theme.spacing.lg,
            borderRadius: theme.radius.md,
            border: `1px solid ${theme.colors.gray[0]}`,
          })}
        >
          <Title order={2} align="center" mb="xl">
            Signup using work account
          </Title>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              id="email_id"
              placeholder="Enter your work email."
              label="Email"
              required={true}
              type="email"
              radius={"md"}
              size={"md"}
              disabled={false || OTP}
              {...form.getInputProps("email")}
            />

            {OTP && (
              <>
                <TextInput
                  id="otp_work"
                  my={"md"}
                  placeholder="Enter the OTP recieved on your email"
                  label={
                    <Group>
                      <Text>OTP</Text>
                      <Text
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOTP(false);
                          setTime(60);
                          form.reset();
                          window.clearInterval(inter);
                        }}
                        underline
                        color="zyla"
                      >
                        Change email?
                      </Text>
                    </Group>
                  }
                  type="tel"
                  radius={"md"}
                  size={"md"}
                  minLength={5}
                  maxLength={5}
                  {...form.getInputProps("otp")}
                />
                <Text
                color="gray"
                style={{
                  cursor: "pointer",
                  opacity: time === 0 ? "1" : "0.4",
                }}
                onClick={async () => {
                  if (time > 1) {
                    return;
                  }
                  window.clearInterval(inter);
                  setTime(60);
                  const interval = setInterval(() => {
                    setTime((time) => {
                      if (time == 1) {
                        window.clearInterval(interval);
                      }
                      return time - 1;
                    });
                  }, 1000);
                  
                  setInter(interval);
                  await GetOTP({ emailid: form.values.email });
                }}
              >
                Resend OTP ({time})
              </Text>
              </>
            )}
            <Text color="red">{error}</Text>
            <Button
              id="signup_button"
              radius={"sm"}
              size={"md"}
              type="submit"
              fullWidth={true}
              disabled={!termsAgree}
              my={"lg"}
            >
              Signup
            </Button>
          </form>
          <Link to="/login">
            <Text id="login_link" color="zyla" weight={"bold"} align="center" underline={true}>
              Login
            </Text>
          </Link>
        </Box>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", marginTop: "16px" }}>
            <input type="checkbox" id="terms" onChange={handleCheckboxChange} style={{ marginTop: "4px" }} />
            <label htmlFor="terms" style={{ marginLeft: "8px", fontFamily: "Montserrat" }}>
              I provide my consent and agree to the{" "}
              <a href="https://zyla.in/terms-content" target="_blank" rel="noopener noreferrer">
                'Terms and conditions'
              </a><br />
              and{" "}
              <a href="https://zyla.in/privacy-content" target="_blank" rel="noopener noreferrer">
                'Privacy policy'.
              </a>
            </label>
          </div>
      </Box>
    </LoginLayout>
  );
};

export default Signup;
