import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { getItem, setItem } from "./storage";

export const getDocumentToken = (): string => {
  const token = getItem("STORAGE_TOKEN");
  if (token && token?.expiredTime > Date.now()) {
    return token?.token;
  } else {
    generateDocumentToken();
    const newToken = getItem("STORAGE_TOKEN");
    return newToken?.token;
  }
  return "";
};

export const generateDocumentToken = async () => {
  try {
    let res = await api.get(ApiConfig.documents.generateToken);
    setItem("STORAGE_TOKEN", res.data);
  } catch {
    console.error("couldn't fetch document token...");
  }
};

export const generateDocumentTokenCustom = async (header:any):Promise<string> => {
  try {
    let res:any = await api.getWithCustomHeader(ApiConfig.documents.generateToken,header);
   
    setItem("STORAGE_TOKEN", res.data);
    return `${res.data.token}`;
  } catch {
    console.error("couldn't fetch document token...");
  }
  return "";
};
