import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  TextInput,
  Group,
  Button,
  Radio,
  Modal,
  RadioGroup,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { GetPhoneOTP } from "../../services/auth";
import { ChevronRight } from "react-feather";
import { getPincodeDetails } from "../../services/address";
import { getItem } from "../../services/storage";

export interface AddressFormValues {
  id?: number;
  name?: string;
  pincode: number | undefined;
  district: string;
  state: string;
  line1: string;
  line2: string;
  landmark?: string;
  phoneNo?: number;
  countryCode?: number;
  type?: string;
  isDefault?: boolean;
  patientId?: number;
  city: string;
}

export interface AddressFormProps {
  initialValues?: AddressFormValues;
  loading: boolean;
  onSubmit(values: AddressFormValues): void;
  type?: boolean;
  CTA?: string;
}

const AddressForm = ({
  loading,
  onSubmit,
  initialValues,
  type,
  CTA,
}: AddressFormProps) => {
  const initial =
    initialValues === undefined
      ? {
          city: "",
          district: "",
          line1: "",
          pincode: undefined,
          state: "",
          line2: "",
          landmark: "",
        }
      : initialValues;
  const profile = getItem("USER_PROFILE")   
  const addressForm = useForm<AddressFormValues>({
    initialValues: {...initial,name:initial.name ?initial.name: `${profile?.patientProfile?.firstName} ${profile?.patientProfile?.lastName}`},
    validate: {
      city: (value) =>
        /^[a-zA-Z ]+$/.test(value) ? null : "Enter correct city name",
      state: (value) =>
        /^[a-zA-Z ]+$/.test(value) ? null : "Enter correct state name",
      district: (value) =>
        value !== "" ? null : "Please enter a district name",
      line1: (value) => (value !== "" ? null : "Please enter a district name"),
      pincode: (value) =>
        /^\d{6}$/.test(String(value))
          ? null
          : "Enter correct PIN code",
      line2: (value) => (value !== "" ? null : "Please enter a street name"),
    },
  });
  return (
    <form
      onSubmit={addressForm.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      {type && (
        <RadioGroup
          name="type"
          label="Address type"
          size="md"
          pb={8}
          {...addressForm.getInputProps("type")}
        >
          <Radio id="home_address" value="Home" label="Home" defaultChecked />
          <Radio id="work_address" value="Work" label="Work" />
          <Radio id="other_address" value="Other" label="Other" />
        </RadioGroup>
      )}
      <TextInput
        required
        size="md"
        id="pincode_onboarding"
        radius={"md"}
        disabled={loading}
        label="Pincode"
        type="number"
        py={8}
        onKeyDown={(e) => {
          if(e.key == "ArrowUp" || e.key == "ArrowDown"){
            e.preventDefault();
          }
        }}
        // onWheel={(e) => {
        //   e.preventDefault();
        // }}
        placeholder="Pincode"
        minLength={6}
        maxLength={6}
        value={addressForm.values.pincode}
        onChange={async (e) => {
          let pincode = e.target.value;
          if (pincode.length <= 6) {
            addressForm.setFieldValue("pincode", parseInt(pincode));
          }
          if (pincode.length === 6) {
            const pincodeState = await getPincodeDetails(parseInt(pincode));
            addressForm.setFieldValue("district", pincodeState.data.district);
            addressForm.setFieldValue("state", pincodeState.data.state);
            addressForm.setFieldValue("city", pincodeState.data.district);
          }
        }}
        error={addressForm.getInputProps("pincode").error}
        ref={(input) => {
          if (input) {
            input.addEventListener("wheel", (e) => e.preventDefault());
          }
        }}
      />
      <Group position="apart">
        <TextInput
          style={{ flex: 1 }}
          required
          size="md"
          id="district_onboarding"
          radius={"md"}
          disabled={loading}
          label="District"
          py={8}
          placeholder="District"
          {...addressForm.getInputProps("district")}
        />
        <TextInput
          style={{ flex: 1 }}
          required
          size="md"
          id="state_onboarding"
          radius={"md"}
          disabled={loading}
          label="State"
          py={8}
          placeholder="State"
          {...addressForm.getInputProps("state")}
        />
      </Group>

      <TextInput
        required
        size="md"
        radius={"md"}
        id="house_onboarding"
        disabled={loading}
        label="House No. / Floor / Building"
        py={8}
        placeholder="House No. / Floor / Building"
        {...addressForm.getInputProps("line1")}
      />

      <TextInput
        required
        size="md"
        id="street_onboarding"
        radius={"md"}
        disabled={loading}
        label="Colony / Street / Area"
        py={8}
        placeholder="Colony / Street / Area"
        {...addressForm.getInputProps("line2")}
      />
      <TextInput
        size="md"
        radius={"md"}
        id="landmark_onboarding"
        disabled={loading}
        label="Landmark ( optional )"
        py={8}
        placeholder="Landmark ( optional )"
        {...addressForm.getInputProps("landmark")}
      />
      <TextInput
        required
        size="md"
        radius={"md"}
        id="city_onboarding"
        disabled={loading}
        label="City"
        py={8}
        placeholder="City"
        {...addressForm.getInputProps("city")}
      />
      <Button mt={"lg"} type="submit" id="add_onboarding">
        {CTA === undefined ? "+ Add" : CTA}
      </Button>
    </form>
  );
};

export default AddressForm;
