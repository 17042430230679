import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  BookAppointmentRequest,
  AppointmentResponse,
  PaymentRequest,
} from "../types/appointment";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { getItem } from "./storage";
import dayjs from "dayjs";

export const createAppointment = (appointment: BookAppointmentRequest) => {
  return api.post(ApiConfig.appointment.createAppointment, appointment);
};

export const rescheduleAppointment = (rescheduleReq: any) => {
  return api.post(ApiConfig.appointment.reschedule, rescheduleReq);
};

export const useAppointmentPast = (
  args?: any
): UseQueryResult<AppointmentResponse> => {
  const user = getItem("USER_PROFILE");
  return useQuery(
    ["appointments_past"],
    async function () {
      const { data } = await api.get(
        ApiConfig.appointment.getAppointments + user?.patientProfile?.id,
        { params: { t: "past" } }
      );
      return data;
    },
    args
  );
};

export const useAppointmentUpcoming = (
  args?: any
): UseQueryResult<AppointmentResponse> => {
  const user = getItem("USER_PROFILE");
  return useQuery(
    ["appointments_upcoming"],
    async function () {
      const { data } = await api.get(
        ApiConfig.appointment.getAppointments + user?.patientProfile?.id,
        { params: { t: "upcoming" } }
      );

      return data;
    },
    args
  );
};

export const useAppointment = (id: number): UseQueryResult => {
  return useQuery(["appointments", id], async function () {
    const { data } = await api.get(ApiConfig.appointment.getAppointment + id);
    return data;
  });
};

export const createOrderId = (req: PaymentRequest): any => {
  const url = ApiConfig.appointment.createOrderId;
  return api.post(url, req);
};
