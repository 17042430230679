import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import clevertap from "clevertap-web-sdk";

clevertap.setLogLevel(0);
clevertap.init(import.meta.env.VITE_CLEVERTAP_ACCOUNTID);
clevertap.spa = true;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);
