export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER_PROFILE = "USER_PROFILE";
export const INITIAL_PROFILE = "INITIAL_PROFILE";
export const STORAGE_TOKEN = "STORAGE_TOKEN";

export const CT = {
  NAVIGO_LANDING_PAGE: "NAVIGO_LANDING_PAGE",
  NAVIGO_SIGNUP: "navigo_signup",
  NAVIGO_LOGIN: "navigo_login",
  NAVIGO_ONBOARDING_START: "navigo_onboarding_start",
  NAVIGO_ONBOARDING_COMPLETE: "navigo_onboarding_complete",
  NAVIGO_HOME: "navigo_home",
  NAVIGO_VIEW_SERVICE: "navigo_view_service",
  NAVIGO_CHOOSE_FAMILY_MEMBER: "navigo_choose_family_member",
  NAVIGO_OPEN_SCHEDULE_SLOT: "navigo_open_schedule_slot",
  NAVIGO_CHECK_SLOTS: "navigo_check_slots",
  NAVIGO_BOOK_APPOINTMENT: "navigo_book_appointment",
  NAVIGO_RESCHEDULE_CHECK: "navigo_reschedule_check",
  NAVIGO_RESCHEDULED: "navigo_rescheduled",
  NAVIGO_CANCEL_CHECK: "navigo_cancel_check",
  NAVIGO_CANCEL: "navigo_cancel",
  NAVIGO_VIEW_FAMILY: "navigo_view_family",
  NAVIGO_ADD_FAMILY: "navigo_add_family",
  NAVIGO_FAMILY_ADDED: "navigo_family_added",
  NAVIGO_VIEW_PROFILE: "navigo_view_profile",
  NAVIGO_EDIT_PROFILE: "navigo_edit_profile",
  NAVIGO_VIEW_MY_APPOINTMENTS: "navigo_view_my_appointments",
  NAVIGO_VIEW_ADDRESS: "navigo_view_address",
  NAVIGO_VIEW_HELP: "navigo_view_help",
};
