import {
  Text,
  Title,
  Grid,
  Box,
  Group,
  Container,
  Button,
  Loader,
} from "@mantine/core";
import { User, Calendar, ChevronRight } from "react-feather";
import { useSearch, Link, useNavigate } from "@tanstack/react-location";
import { ReactNode, useEffect, useState } from "react";

import { api } from "../utils/api";

import LoginLayout from "../layouts/LoginLayout";
import ApiConfig from "../utils/apiconfig";
import { render } from "react-dom";

const BookingConfirmed = () => {
  const { orderId,type } = useSearch();
  const navigate = useNavigate();

  const [intervalId, setIntervalId] = useState<number>();
  const [bookingStatus, setBookingStatus] = useState<any>({ status: 0 });
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    const int = setInterval(getStatus, 1000, orderId);
    // console.log("Interval id: ", int);
    setIntervalId(int);
  }, []);

  useEffect(() => {
    if (bookingStatus?.status != 0) {
      clearInterval(intervalId);
      setFinished(true);
    }
  }, [bookingStatus]);

  async function getStatus(orderId: string) {
    const { data } = await api.get(ApiConfig.appointment.getStatus + orderId);
    // const data = { status: 0 };
    setBookingStatus(data);
  }

  return (
    <LoginLayout scrollHidden>
      <Container
        size="sm"
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          })}
        >
          <Text size="xl" py={4}>
            ✅
          </Text>
          <Title order={2} mb={20}>
            Payment complete
          </Title>

          {renderStatus(bookingStatus?.status,`${type}`)}

          {finished && (
            <Button
              mt="lg"
              size="lg"
              fullWidth={true}
              onClick={() => navigate({ to: "/" })}
            >
              <Group>
                <Text>Continue to dashboard</Text>
                <ChevronRight />
              </Group>
            </Button>
          )}
        </Box>
      </Container>
    </LoginLayout>
  );
};

// const getBookingMsg=(type:string)=>{
//   if(type=="Radiology test"){
//     return "We have received your booking; you will receive a confirmation email in your work email ID shortly";
//   }
//   return ""
// }
const getBookingMsg=(type:string)=>{
  if(type=="Radiology test"){
    return <div className="">
      <Text size="xl" py={4} align="center" weight={600}>
      Booking recieved
        </Text>
        <Text size="xl" py={4} align="center">
        We have received your booking, you will receive a confirmation on your work email ID shortly
        </Text>
        <br />
    </div>
    
  }
  return  <Text align="center">✅ Appointment created</Text>

}



const renderStatus = (status: number,type:string) => {
  if (status == 0) {
    return (
      <>
        <Loader />
        <Text align="center">Creating appointment</Text>
      </>
    );
  }
  if (status == 1) {
    return getBookingMsg(type);
  }
  if (status == 2) {
    return (
      <Text align="center">
        ⚠️ We couldn't confirm your booking, please try again after some time.
        If any sum is deducted at your end it will be refunded within 7 working
        days.
      </Text>
    );
  }
};

export default BookingConfirmed;
