import {
  Link,
  useMatch,
  useNavigate,
  useSearch,
} from "@tanstack/react-location";
import { useService, useUserService } from "../services/services";
import BookingLayout from "../layouts/BookingLayout";
import {
  Box,
  Loader,
  Text,
  Button,
  Grid,
  Container,
  SimpleGrid,
  Notification,
  Badge,
} from "@mantine/core";
import { getFamilyMembers, getRelationList } from "../services/family";
import { ChevronRight } from "react-feather";
import femaleAvatar from "../assets/female.svg";
import maleAvatar from "../assets/male.svg";
import { useEffect, useState } from "react";
import { GetProfile } from "../services/auth";
import clevertap from "clevertap-web-sdk";
import { CT } from "../constants";
import { useAddress } from "../services/address";
import { Address } from "../types/address";
import { CheckPincodeAvailibilty } from "../services/labtest";

function PriceComponent(props: any) {
  const { isLoading, status, data, error } = useUserService(
    parseInt(props.serviceId),
    true,
    props.patientId
  );
  if (isLoading) {
    return <Loader></Loader>;
  }
  return <Text>₹ {data?.service.discountedPrice}</Text>;
}

const FamilyCard = ({
  gender,
  name,
  description,
  id,
  onClick,
  selected,
  disabled,
  serviceId,
  showPrice,
}: {
  gender: number;
  name: string;
  description: string;
  id: number;
  onClick: (id: number) => void;
  selected: boolean;
  disabled?: boolean;
  serviceId: number;
  showPrice: boolean;
}) => (
  <Box
    id={String(id)}
    sx={(theme) => ({
      width: "100%",
      padding: theme.spacing.lg,
      border: `${selected ? "1.2px" : "1px"} solid ${
        selected ? theme.colors.indigo[5] : theme.colors.gray[2]
      }`,
      cursor: "pointer",
      borderRadius: theme.radius.md,
      alignItems: "center",
      alignContent: "center",
      textAlign: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      "&:hover": {
        borderColor: theme.colors.gray[6],
      },

      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        flexDirection: "row",
        justifyContent: "flex-start",
      },

      opacity: disabled ? 0.5 : 1,
    })}
    onClick={() => {
      if (disabled) {
        return false;
      }
      onClick(id);
    }}
  >
    <img src={gender === 1 ? femaleAvatar : maleAvatar} />
    <Box
      sx={(theme) => ({
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          textAlign: "left",
          paddingLeft: 20,
        },
      })}
    >
      <Text size="xl">{name.slice(0, 20)}</Text>
      <Text size="sm" color={"gray"}>
        {description}
      </Text>
      {showPrice ? (
        <PriceComponent serviceId={serviceId} patientId={id} />
      ) : (
        <></>
      )}
      {disabled ? (
        <Badge mt={12} variant="dot" color={"green"}>
          Availed
        </Badge>
      ) : (
        ""
      )}
    </Box>
  </Box>
);

const AddressCard = ({
  index,
  serviceId,
  address,
  onClick,
  selected,
  checkServicable,
}: {
  index: string;
  serviceId: number;
  address: Address;
  onClick: (id: number) => void;
  selected: boolean;
  checkServicable: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [isServicable, setIsServicable] = useState(false);
  useEffect(() => {
    // setLoading(false);
    if (checkServicable) {
      (async function () {
        try {
          const isServicable = await CheckPincodeAvailibilty(
            address?.id as number,
            serviceId
          );
          setLoading(false);
          setIsServicable(isServicable);
        } catch {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <Box
      id={`appointment_address_card_${index}`}
      sx={(theme) => ({
        width: "100%",
        padding: theme.spacing.lg,
        border: `${selected ? "1.2px" : "1px"} solid ${
          selected ? theme.colors.indigo[5] : theme.colors.gray[2]
        }`,
        cursor: "pointer",
        borderRadius: theme.radius.md,
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
        justifyContent: "center",
        "&:hover": {
          borderColor: theme.colors.gray[6],
        },
        opacity: checkServicable ? (isServicable ? 1 : 0.7) : 1,
      })}
      onClick={() => {
        if (!loading && !isServicable && checkServicable) {
          return;
        }

        onClick(address.id as number);
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text size="xl">{address.name}</Text>
          <Text size="sm" color={"gray"}>
            {/* {`${address.line1} ${address.line2} ${address.city} ${address.district} ${address.state} ${address.pincode}`} */}
            <Text style={{fontWeight: 'bold'}}>{address.type ? address.type.charAt(0).toUpperCase() + address.type.slice(1).toLowerCase() : 'Address'}</Text>
            <Text>{`${address.line1}, ${address.line2}, ${address.landmark}, ${address.city}, ${address.district}, ${address.state} - ${address.pincode}`}</Text>
          </Text>
          {checkServicable ? (
            !isServicable ? (
              <Badge mt={20} color={"red"}>
                Address not servicable
              </Badge>
            ) : (
              <Badge mt={20} color={"green"}>
                Address servicable
              </Badge>
            )
          ) : (
            ""
          )}
        </>
      )}
    </Box>
  );
};

/*

*/
const Booking = () => {
  const {
    params: { sid },
  } = useMatch();
  const { partner } = useSearch();
  const navigate = useNavigate();
  const profile = GetProfile();
  const { isLoading, status, data, error } = useUserService(parseInt(sid));
  const [selectedMember, setSelectedMember] = useState(-1);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [availedAll, setAvailedAll] = useState(true);

  const { data: relationMappingData, isLoading: isLoadingFamily } =
    getRelationList();
  const { data: family, isLoading: isFamilyMembersLoading } = getFamilyMembers({
    enabled: !isLoadingFamily,
  });

  const { data: addresses, isLoading: isLoadingAddress } = useAddress();

  useEffect(() => {
    if (!isLoading) {
      clevertap.event.push(CT.NAVIGO_CHOOSE_FAMILY_MEMBER, {
        serviceID: data?.service?.ID,
        serviceName: data?.service?.name,
      });
    }
    if (
      !isLoading &&
      !data?.service?.availableForFamily &&
      !data?.service?.isLabTest &&
      !data?.service?.addressRequired
    ) {
      navigate({ to: "/schedule/" + data?.service?.ID });
    }
  }, [data]);

  useEffect(() => {
    if (!isFamilyMembersLoading) {
      let membersArray = [0];
      family?.map((f) => membersArray.push(f.memberPatientId));

      console.log(membersArray);

      for (let i = 0; i < membersArray.length; i++) {
        if (data?.availedFor.indexOf(membersArray[i]) === -1) {
          setAvailedAll(false);
        }
      }
    }
  }, [isFamilyMembersLoading]);


  return (
    <BookingLayout title={data?.service?.name} step={0}>
      {isLoading ? (
        <Loader size={"sm"} />
      ) : (
        <>
          {data?.service?.availableForFamily && (
            <>
              <Text size="lg" align="center" mb="md">
                Booking for
              </Text>
              <Container size={"lg"} style={{ width: "100%" }}>
                <SimpleGrid
                  style={{ width: "100%" }}
                  cols={3}
                  breakpoints={[
                    { maxWidth: 980, cols: 3, spacing: "md" },
                    { maxWidth: 755, cols: 2, spacing: "sm" },
                    { maxWidth: 600, cols: 1, spacing: "sm" },
                  ]}
                >
                  <FamilyCard
                    gender={profile?.patientProfile.gender}
                    name={
                      profile?.patientProfile.firstName.charAt(0).toUpperCase() + profile?.patientProfile.firstName.slice(1).toLowerCase() 
                      + " "
                      + profile?.patientProfile.lastName.charAt(0).toUpperCase() + profile?.patientProfile.lastName.slice(1).toLowerCase()
                    }                
                    description={`Self`}
                    id={0}
                    disabled={data?.availedFor.indexOf(0) !== -1}
                    onClick={(id) => setSelectedMember(0)}
                    selected={selectedMember === 0}
                    showPrice={false}
                  />

                  {!isLoadingFamily &&
                    family?.map((f) => (
                      <div onClick={() => setSelectedMember(f.memberPatientId)}>
                        <FamilyCard
                          showPrice={true}
                          gender={f.gender}
                          name={f.firstName.charAt(0).toUpperCase() + f.firstName.slice(1).toLowerCase() 
                            + " " 
                            + f.lastName.charAt(0).toUpperCase() + f.lastName.slice(1).toLowerCase()}
                          // description={`(${f.age === 0 ? "" : f.age}, ${
                          description={`${
                            relationMappingData?.filter(
                              (r) => r.code == f.relation
                            )[0].value
                          }`}
                          disabled={
                            data?.availedFor.indexOf(f.memberPatientId) !== -1
                          }
                          id={f.memberPatientId}
                          serviceId={data?.service?.ID}
                          // onClick={(id) => setSelectedMember(id)}
                          selected={selectedMember === f.memberPatientId}
                        />
                      </div>
                    ))}
                </SimpleGrid>
                {/* {availedAll && (
                  <Container size={"md"} mt={20}>
                    <Notification disallowClose>
                      Services have been availed for all the existing members,
                      please add members as per your plan to avail of the
                      service for them or contact (care@zyla.in) for further
                      clarification
                    </Notification>
                  </Container>
                )} */}
                <Link to="/family">
                  <Text 
                    id="add_family"
                    mt="lg"
                    color={"zyla"}
                    underline={false}
                    weight="semibold"
                    align="center"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    Add family member
                  </Text>
                </Link>
              </Container>
            </>
          )}
          {(data?.service?.isLabTest || data?.service?.addressRequired) && (
            <>
              <Text size="lg" align="center" mb="md" mt="lg">
                Choose an address
              </Text>
              {!isLoadingAddress && addresses?.length === 0 && (
                <Text align="center" color={"gray"}>
                  No address found. Please add an address before continuing.
                </Text>
              )}
              <Container size={"lg"} style={{ width: "100%" }}>
                <SimpleGrid
                  id={`appointment_address_card `}
                  cols={3}
                  breakpoints={[
                    { maxWidth: 980, cols: 3, spacing: "md" },
                    { maxWidth: 755, cols: 2, spacing: "sm" },
                    { maxWidth: 600, cols: 1, spacing: "sm" },
                  ]}
                >
                  {!isLoadingAddress &&
                    addresses?.map((a, index) => (
                      <AddressCard
                        index={`${index}`}
                        // checkServicable={false}
                        serviceId={data?.service?.ID}
                        checkServicable={data?.service?.isLabTest}
                        address={a}
                        onClick={(id) => setSelectedAddress(id)}
                        selected={selectedAddress === a.id}
                      />
                    ))}
                </SimpleGrid>
                <Link to="/address">
                  <Text
                    id="add_address_appointment"
                    mt="lg"
                    color={"zyla"}
                    underline={false}
                    weight="semibold"
                    align="center"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    Add address
                  </Text>
                </Link>
              </Container>
            </>
          )}
          <Button
            id="continue_appointment"
            disabled={
              (data?.service?.addressRequired && selectedAddress == 0) ||
              (data?.service?.availableForFamily && selectedMember == -1)
            }
            onClick={() =>
              navigate({
                to: "/schedule/" + data?.service?.ID,
                search: {
                  pid: selectedMember == -1 ? 0 : selectedMember,
                  aid: selectedAddress,
                  external: data?.service?.isLabTest ? 1 : 0,
                  ...(data?.service?.isLabTest
                    ? {
                        pincode: addresses?.filter(
                          (a) => a.id == selectedAddress
                        )[0].pincode,
                      }
                    : {}),
                  ...(partner
                    ? {
                        partner: partner,
                      }
                    : {}),
                },
              })
            }
            style={{ width: "200px", margin: "40px auto" }}
            size="lg"
            py={12}
            mb={40}
          >
            Continue <ChevronRight />
          </Button>
        </>
      )}
    </BookingLayout>
  );
};

export default Booking;
