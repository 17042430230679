import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { Relation, GetMemberResponse } from "../types/family";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";

export const getRelationList = (): UseQueryResult<Relation[]> => {
  return useQuery(["relations"], async function () {
    const { data } = await api.get(ApiConfig.family.getAllRelations);
    return data;
  });
};

export const getFamilyMembers = (
  args: any
): UseQueryResult<GetMemberResponse[]> => {
  return useQuery(
    ["family-members"],
    async function () {
      const { data } = await api.get(ApiConfig.family.getMembers);
      return data;
    },
    args
  );
};
