import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Card,
} from "@mantine/core";
import { Calendar, ChevronRight, Clock, User, X } from "react-feather";
import HomeLayout from "../layouts/HomeLayout";
import OnboardingForm from "../components/onboarding";
import clevertap from "clevertap-web-sdk";
import { CT } from "../constants";

const Index = () => {
  useEffect(() => {
    clevertap.event.push(CT.NAVIGO_VIEW_HELP);
  }, []);
  return (
    <HomeLayout>
      <Box py={40}>
        <Title mb={8}>Help</Title>
        <Text py={12}>
          For assistance please write to us at{" "}
          <a href="mailto:care@zyla.in">care@zyla.in</a> or send a whatsapp on
          <a href="https://wa.me/918595914765" target="_blank">
            +91 85959 14765
          </a>
        </Text>
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;
