import ApiConfig from "../utils/apiconfig";
import { api } from "../utils/api";
import {
  AppointmentResponse,
  BookAppointmentRequest,
} from "../types/appointment";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { Slots } from "../types/service";
import {
  RcBookingRequest,
  RCPatchRequest,
  RcSlotRequest,
  RcSlotResponse,
} from "../types/rc";
import dayjs from "dayjs";

interface GetLabTestSlotRequest {
  date: string;
  serviceId: string;
  pincode: string;
}

export const CheckPincodeAvailibilty = async (
  addressId: number,
  serviceId: number
): Promise<boolean> => {
  let data = await api.post<any>(ApiConfig.labtest.checkAvailibility, {
    serviceId: serviceId,
    addressId: addressId,
  });
  return data.data?.serviceable;
};

export const GetLabtestSlots = (data: GetLabTestSlotRequest) => {
  return api.post(ApiConfig.labtest.getSlots, data);
};

export const BookLabTest = (data: BookAppointmentRequest) => {
  return api.post<BookAppointmentRequest>(ApiConfig.labtest.bookSlot, data);
};

export const BookLabTestRC = (data: RcBookingRequest) => {
  return api.post<BookAppointmentRequest>(ApiConfig.labtestRc.bookSlot, data);
};

export const useLabtestSlots = (
  id: number,
  date: string,
  pincode: string,
  args: any
): UseQueryResult<Slots> => {
  return useQuery<Slots, Error>(
    ["lab-testslots", { serviceId: id, date: date }],
    async function () {
      const { data } = await api.post<Slots>(ApiConfig.services.getSlots, {
        date: date,
        serviceId: id.toString(),
        pincode: pincode,
      });
      return data;
    },
    args
  );
};

export const GetRCSlots = async (req: RcSlotRequest): Promise<Slots> => {
  const res = await api.post<RcSlotResponse>(ApiConfig.labtestRc.getSlots, req);
  if (res.status == 200) {
    const slots = res.data?.slots.map((s) => s.value).sort((a, b) => a - b);
    return {
      date: dayjs(req.collectionDate).toDate(),
      serviceID: req.serviceId,
      slot: slots,
    };
  }
  return {
    date: dayjs(req.collectionDate).toDate(),
    serviceID: req.serviceId,
    slot: [],
  };
};

export const GetRCSlotId = async (
  req: RcSlotRequest,
  slot: number
): Promise<number> => {
  const res = await api.post<RcSlotResponse>(ApiConfig.labtestRc.getSlots, req);
  if (res.status == 200) {
    const slots = res.data?.slots.filter((s) => s.value == slot);
    if (slots.length > 0) {
      return slots[0].key;
    }
  }
  return 0;
};

export const PatchRCAppointment = (req: RCPatchRequest) => {
  return api.put(ApiConfig.labtestRc.bookSlot, req);
};
