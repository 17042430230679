import axios from "axios";
import { GetAuthHeaders } from "../services/auth";
import { getItem } from "../services/storage";

export const api = {
  getWithCustomHeader: async <T>(url: string,customHeaders:any, params?: object) => {
    const headers = await GetAuthHeaders();
    return axios.get<T>(url, {
      headers: customHeaders,
      ...params,
    });
  },
  get: async <T>(url: string, params?: object) => {
    const headers = await GetAuthHeaders();
    return axios.get<T>(url, {
      headers: headers,
      ...params,
    });
  },
  post: async <T>(url: string, data: any) => {
    const headers = await GetAuthHeaders();
    return axios.post<T>(url, data, {
      headers: headers,
    });
  },
  postWithoutToken: async <T>(url: string, data: any) => {
    return axios.post<T>(url, data, {
      headers: {
        client: "patient_android",
      },
    });
  },
  put: async <T>(url: string, data: any) => {
    const headers = await GetAuthHeaders();
    return axios.put<T>(url, data, {
      headers: headers,
    });
  },
  patch: async <T>(url: string, data: any) => {
    const headers = await GetAuthHeaders();
    return axios.patch<T>(url, data, {
      headers: headers,
    });
  },
  delete: async <T>(url: string) => {
    const headers = await GetAuthHeaders();
    return axios.delete<T>(url, {
      headers: headers,
    });
  },
};
