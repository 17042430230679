import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Button,
  Group,
  Loader,
  Modal,
} from "@mantine/core";
import HomeLayout from "../layouts/HomeLayout";
import { GetProfile } from "../services/auth";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import empty from "../assets/family-empty.svg";
import { CT, USER_PROFILE } from "../constants";
import dayjs from "dayjs";
import AddressForm, { AddressFormValues } from "../components/address";

import clevertap from "clevertap-web-sdk";
import { editAddress, saveAddress, useAddress, deleteAddress } from "../services/address";
import { Address } from "../types/address";
import { queryClient } from "../App";

const AddressEmpty = ({ onClick }: { onClick: any }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        padding: "20px 0px",
      }}
    >
      <img
        src={empty}
        alt="You can add your family members here to avail available services for them."
      />
      <Text my={16} color={"dark"}>
        You can add your addresses here to avail services on them.
      </Text>
      <Button size="md" radius={"md"} onClick={() => onClick(true)} id="add_address_2">
        + Add a new address
      </Button>
    </Box>
  );
};

const capitalizeEachWord = (sentence : string) => {
  return sentence
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const AddressCard = ({
  index,
  d,
  setEditAddressID,
  setRemoveAddress,
  setRemoveAddressId,
}: {
  index: string;
  d: Address;
  setEditAddressID: (id: number) => void;
  setRemoveAddress: (value: boolean) => void;
  setRemoveAddressId: (val: number) => void;
}) => (
  <Box id={`address_card_${index}`}
    sx={(theme) => ({
      border: `1px solid ${theme.colors.gray[2]}`,
      padding: theme.spacing.lg,
      borderRadius: theme.radius.md,
      // margin: `${theme.spacing.md}px 0px `,
    })}
  >
    <Text style={{}}>{d.name}</Text>
    <Text style={{fontWeight: 'bold'}}>{d.type ? d.type.charAt(0).toUpperCase() + d.type.slice(1).toLowerCase() : 'Address'}</Text>
    <Text>{`${d.line1}, ${d.line2}, ${d.landmark}${d.landmark ? ", ":""}${capitalizeEachWord(d.city)}, ${capitalizeEachWord(d.district)}, ${capitalizeEachWord(d.state)} - ${d.pincode}`}</Text>
    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
      <Button
        id={`edit_address_card_${index}`}
        uppercase
        variant="white"
        compact
        mt={8}
        onClick={() => setEditAddressID(d.id as number)}
      >
        Edit
      </Button>
      <Button id={`remove_address_card_${index}`}
        uppercase
        variant="white"
        compact
        mt={8}
        onClick={() => {
          setRemoveAddress(true); 
          setRemoveAddressId(d.id as number)
        }}
      >
        Remove
      </Button>
    </div>
  </Box>
);

const Index = () => {
  const profile = GetProfile();
  const { isLoading, data, error } = useAddress();
  const [loading, setLoading] = useState<boolean>(false);
  const [editAddressID, setEditAddressID] = useState<undefined | number>();
  const [addAddressModal, setAddAddressModal] = useState<boolean>(false);
  const [removeAddress, setRemoveAddress] = useState<undefined | boolean>(false);
  const [removeAddressId, setRemoveAddressId] = useState<undefined | number>();

  const [editingAddress, setEditingAddress] = useState<AddressFormValues>();

  useEffect(() => {
    const address = data?.filter((a) => a.id === editAddressID)[0];
    setEditingAddress(address);
  }, [editAddressID]);

  useEffect(() => {
    clevertap.event.push(CT.NAVIGO_VIEW_PROFILE);
    console.log("profile", profile)
  }, []);

  const handleEditAddress = async (address: AddressFormValues) => {
    setLoading(true);
    try {
      await editAddress(address.id as number, address);

      await queryClient.invalidateQueries(["address"]);
      setEditAddressID(undefined);
    } catch {}
    setLoading(false);
  };

  const handleAddAddress = async (address: AddressFormValues) => {
    setLoading(true);
    try {
      await saveAddress(address);

      await queryClient.invalidateQueries(["address"]);
      setAddAddressModal(false);
    } catch {}
    setLoading(false);
  };

  const removePatientAddress = async (id: number) => {
    setLoading(true);
    try {
      await deleteAddress(id as number);
      await queryClient.invalidateQueries(["address"]);
      setRemoveAddress(false);
    } catch {}
    setLoading(false)
  }

  return (
    <HomeLayout>
      <Box py={40}>
        <Group position="apart">
          <Title mb={8}>Addresses</Title>
          <Button
            id="add_address_1"
            size="xs"
            radius={"md"}
            onClick={() => setAddAddressModal(true)}
          >
            + Add new address
          </Button>
        </Group>
        <Modal
          opened={addAddressModal}
          onClose={() => setAddAddressModal(false)}
          title="Add address"
        >
          <AddressForm
            CTA="+ Save address"
            type
            loading={loading}
            onSubmit={(values) => handleAddAddress(values)}
          />
        </Modal>
        <Modal
          opened={editAddressID != undefined}
          onClose={() => setEditAddressID(undefined)}
          title="Edit address"
        >
          <AddressForm
            CTA="Save"
            initialValues={editingAddress}
            type
            loading={loading}
            onSubmit={(values) => handleEditAddress(values)}
          />
        </Modal>
        <Modal
          opened={removeAddress}
          onClose={() => setRemoveAddress(false)}
          title="Remove address"
        >
          <div>
            <h3>Are you sure you want to remove this address?</h3>
            <div style={{display:"flex", justifyContent:"center", gap:"15px"}}>
              <Button id="remove_address_back"
                onClick={() => setRemoveAddress(false)}
              >
                Back
              </Button>
              <Button id="remove_address_yes"
                onClick={() => removePatientAddress(removeAddressId as number)}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        {!isLoading && data?.length === 0 && (
          <AddressEmpty onClick={() => setAddAddressModal(true)} />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <Grid mt={20}>
            {data?.map((d,index) => (
              <Grid.Col xs={6} span={12} key={d.id}>
                <AddressCard
                  index={`${index}`}
                  d={d}
                  setEditAddressID={(id) => setEditAddressID(id)}
                  setRemoveAddress={(value) => setRemoveAddress(value)}
                  setRemoveAddressId={(val) => setRemoveAddressId(val)}
                />
              </Grid.Col>
            ))}
          </Grid>
        )}
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;


