import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { Address, PincodeResponse } from "../types/address";
import { GetProfile } from "./auth";

export const useAddress = (): UseQueryResult<Address[]> => {
  return useQuery(["address"], async function () {
    const { data } = await api.get(ApiConfig.address.getAll);
    return data;
  });
};

export const saveAddress = (address: Address) => {
  const profile = GetProfile();
  address.patientId = profile?.patientProfile?.id;
  address.countryCode = profile?.patientProfile?.countryCode;
  address.phoneNo = profile?.phoneNo;
  address.pincode = parseInt((address.pincode as number).toString());
  return api.post(ApiConfig.address.save, address);
};

export const editAddress = (id: number, address: Address) => {
  const profile = GetProfile();
  address.patientId = profile?.patientProfile?.id;
  address.countryCode = profile?.patientProfile?.countryCode;
  address.phoneNo = profile?.phoneNo;
  address.pincode = parseInt((address.pincode as number).toString());
  return api.put(
    ApiConfig.address.update.replace("%d", id.toString()),
    address
  );
};

export const deleteAddress = (id: number) => {
  return api.delete(ApiConfig.address.delete.replace("%d", id.toString()));
};

export const getPincodeDetails = (pincode: number) => {
  return api.get<PincodeResponse>(
    ApiConfig.address.getPincodeDetails.replace("%d", pincode.toString())
  );
};
