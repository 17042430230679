import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
  Card,
  Loader,
} from "@mantine/core";
import { Calendar, ChevronRight, Clock, User, X } from "react-feather";
import { useNavigate } from "@tanstack/react-location";
import HomeLayout from "../layouts/HomeLayout";
import AppointmentCard, { EmptyAppointment } from "../components/appointment";
import {
  useAppointmentPast,
  useAppointmentUpcoming,
} from "../services/appointment";
import dayjs from "dayjs";
import { GetStringSlot } from "../utils/helpers";
import { api } from "../utils/api";
import ApiConfig from "../utils/apiconfig";
import { queryClient } from "../App";
import clevertap from "clevertap-web-sdk";
import { CT } from "../constants";

const Index = () => {
  const { data: appointmentsData } = useAppointmentUpcoming();
  const { data: appointmentsDataPast } = useAppointmentPast();
  
  const [cancel, setCancel] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    clevertap.event.push(CT.NAVIGO_VIEW_MY_APPOINTMENTS);
  }, []);

  const handleCancel = async () => {
    setLoading(true);
    try {
      const res = await api.post(ApiConfig.appointment.cancelAppointment, {
        appointmentID: cancel,
        status: 3,
      });
      await queryClient.invalidateQueries(["appointments_upcoming"]);
      await queryClient.invalidateQueries(["appointments_past"]);
      clevertap.event.push(CT.NAVIGO_CANCEL, {
        appointmentID: cancel,
      });
      setCancel(0);
    } catch {}
    setLoading(false);
  };

  return (
    <HomeLayout>
      <Modal
        title={
          <Text weight="bold">
            Are you sure you want to cancel this appointment?
          </Text>
        }
        opened={cancel !== 0}
        onClose={() => setCancel(0)}
        centered
        size={"md"}
      >
        <Button
          id="handle_cancel_appointment"
          color="red"
          variant="light"
          onClick={() => handleCancel()}
          disabled={loading}
        >
          {loading && <Loader mr="md" color="white" size="xs" />}Cancel
          appointment
        </Button>
        <Button id="back_cancel" color="gray" variant="light" onClick={() => setCancel(0)}>
          Back
        </Button>
      </Modal>
      <Box py={40}>
        <Title mb={8}>Appointments</Title>
        <Text size="lg" weight={"bold"} mt={44} pb={20}>
          Upcoming appointments
        </Text>
        <Grid>
          {appointmentsData?.appointments == null && <EmptyAppointment />}
          {appointmentsData?.appointments?.map((e: any, index: any) => (
            <Grid.Col xs={4} span={12}>
              <AppointmentCard
                index={index}
                orderId={e?.appointment?.paymentOrderId}
                onCancel={() => setCancel(e?.appointment?.ID)}
                onReschedule={() =>
                  navigate({ to: "/reschedule/" + e?.appointment?.ID })
                }
                status={e?.appointment?.status}
                serviceTitle={e?.service?.name}
                paymentOrderId={e?.appointment?.paymentOrderId}
                // name={"Self"}
                name={e?.bookedForPatient?.firstName ? e?.bookedForPatient?.firstName : "Self" }
                date={dayjs(e?.appointment?.appointmentDate).format(
                  "DD, MMM YYYY"
                )}
                timeSlot={GetStringSlot(e?.appointment?.timeSlot)}
              />
            </Grid.Col>
          ))}
        </Grid>

        <Text size="lg" weight={"bold"} mt={40} pb={20}>
          Past appointments
        </Text>
        <Grid>
          {appointmentsDataPast?.appointments?.length == null && (
            <EmptyAppointment />
          )}
          {appointmentsDataPast?.appointments?.map((e: any) => (
            <Grid.Col xs={4} span={12}>
              <AppointmentCard
                status={e?.appointment?.status}
                past
                serviceTitle={e?.service?.name}
                name={e?.bookedForPatient?.firstName ? e?.bookedForPatient?.firstName : "Self" }
                date={dayjs(e?.appointment?.appointmentDate).format(
                  "DD, MMM YYYY"
                )}
                timeSlot={GetStringSlot(e?.appointment?.timeSlot)}
                link={e?.appointment?.ID}
                paymentOrderId={e?.appointment?.paymentOrderId}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Box>{" "}
    </HomeLayout>
  );
};

export default Index;
