import {
  Container,
  Grid,
  Box,
  Group,
  Title,
  Stepper,
  Text,
  Button,
  Loader,
} from "@mantine/core";
import LoginLayout from "../layouts/LoginLayout";
import { Link, useLocation, useRouter } from "@tanstack/react-location";
import { ArrowLeft, Clock, ChevronRight } from "react-feather";

const BookingLayout = ({
  children,
  title,
  step,
}: {
  children: any;
  title: string | undefined;
  step: number;
}) => {
  const location = useLocation();
  const isBasicDetailsPage = location.current.pathname.startsWith('/basic-details/');
  return (
    <LoginLayout>
      <Container size={"sm"} mb={20} style={{ width: "100%" }}>
        {isBasicDetailsPage ? (
          <a href="#" onClick={() => location.history.back()} style={{ textDecoration: "none", color: "inherit" }}>
            <Group
              align={"center"}
              pb={20}
              pt={20}
              style={{ margin: "0 auto", justifyContent: "center" }}
            >
              <ArrowLeft />
              <Text>Back</Text>
            </Group>
          </a>
        ) : (
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <Group
              align={"center"}
              pb={20}
              pt={20}
              style={{ margin: "0 auto", justifyContent: "center" }}
            >
              <ArrowLeft />
              <Text>Back to home</Text>
            </Group>
          </Link>
        )}
        <Title order={2} align="center">
          {title}
        </Title>
        <Stepper color={"indigo"} py={16} iconSize={42} size="sm" active={step}>
          <Stepper.Step label="Basic details" />
          <Stepper.Step label="Schedule" />
        </Stepper>
      </Container>
      {children}
    </LoginLayout>
  );
};

export default BookingLayout;
