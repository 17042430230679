import {
  Text,
  Title,
  Grid,
  Box,
  Group,
  Container,
  Button,
} from "@mantine/core";
import { User, Calendar, ChevronRight } from "react-feather";
import { useSearch, Link, useNavigate } from "@tanstack/react-location";
import type { ReactNode } from "react";

import LoginLayout from "../layouts/LoginLayout";

interface BookingInfoCardProps {
  icon: ReactNode;
  text: string;
}
const BookingInfoCard = ({ icon, text }: BookingInfoCardProps) => (
  <Box
    sx={(theme) => ({
      width: "100%",
      boxShadow: theme.shadows.sm,
      border: `1px solid ${theme.colors.gray[4]}`,
      borderRadius: theme.radius.md,
    })}
    p={20}
    my={8}
  >
    <Group>
      {icon}
      <Text>{text}</Text>
    </Group>
  </Box>
);

const BookingConfirmed = () => {
  const { name, date,type } = useSearch();
  const navigate = useNavigate();

  const getBookingMsg=(type:string)=>{
    if(type=="Radiology test"){
      return <div className="">
        <Text size="xl" py={4} align="center" weight={600}>
        Booking recieved
          </Text>
          <Text size="xl" py={4} align="center">
          We have received your booking, you will receive a confirmation on your work email ID shortly
          </Text>
          <br />
      </div>
      
    }
    return  <Title order={2} mb={20}>
    Booking complete
  </Title>
  
  }

  return (
    <LoginLayout scrollHidden>
      <Container
        size="sm"
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={() => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          })}
        >
          <Text size="xl" py={4}>
            🎉
          </Text>

          {getBookingMsg(`${type}`)}
         
          {/* <BookingInfoCard text={name as string} icon={<User />} />
          <BookingInfoCard text={date as string} icon={<Calendar />} /> */}
          <Button
            id="continue_dashboard"
            size="lg"
            fullWidth={true}
            onClick={() => navigate({ to: "/" })}
          >
            <Group>
              <Text>Continue to dashboard</Text>
              <ChevronRight />
            </Group>
          </Button>
        </Box>
      </Container>
    </LoginLayout>
  );
};

export default BookingConfirmed;
