const storagePrefix = "zy.navigo";

export const setItem = (key: string, value: any) => {
  localStorage.setItem(`${storagePrefix}.${key}`, JSON.stringify(value));
};

export const getItem = (key: string): any => {
  let str = localStorage.getItem(`${storagePrefix}.${key}`);
  if (str) {
    return JSON.parse(str);
  }
  return null;
};

export const clearItem = (key: string) => {
  localStorage.removeItem(`${storagePrefix}.${key}`);
};
