import {
  Box,
  Text,
  Title,
  Grid,
  Image,
  Button,
  Group,
  Modal,
} from "@mantine/core";
import React from "react";
import { Calendar, ChevronRight, Clock, User, X } from "react-feather";

type Info = {
  icon: React.ReactNode;
  title: string;
  action?: () => void;
  type?: string;
};

interface CardProps {
  title: String;
  meta?: React.ReactNode;
  info?: Info[];
  actions?: Info[];
}

const CustomCard = ({ title, meta = "", info, actions }: CardProps) => (
  <Box
    p={24}
    sx={(theme) => ({
      border: `1px solid ${theme.colors.gray[3]}`,
      borderRadius: theme.radius.md,
      boxShadow: theme.shadows.xs,
    })}
  >
    <Group position="apart">
      <Text size="md" weight={"bold"}>
        {title.slice(0, 17)}
      </Text>
      {meta !== "" && (
        <Button variant="light" color={"indigo"} radius="md" size="xs">
          {meta}
        </Button>
      )}
    </Group>
    <Group pt={"xs"} pb={"md"}>
      {info?.map((i) => (
        <Group spacing={"xs"} key={i.title}>
          {i.icon}
          <Text size="sm">{i.title}</Text>
        </Group>
      ))}
    </Group>
    <Group>
      {actions?.map((a, index) => (
        <Button
          id = {`${a.title}_${index}`}
          variant="light"
          color={a.type}
          size="xs"
          radius={"md"}
          onClick={a.action}
          key={a.title}
        >
          {a.icon}
          {a.title}
        </Button>
      ))}
    </Group>
  </Box>
);

export default CustomCard;
