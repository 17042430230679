import react, { ReactNode } from "react";
import { Container, Grid } from "@mantine/core";
import Logo from "../assets/logo.svg";

interface LayoutProps {
  children: ReactNode;
  scrollHidden?: boolean;
}

const LoginLayout = ({ children, scrollHidden }: LayoutProps) => (
  <Container
    size={"lg"}
    style={{
      display: "flex",
      flexDirection: "column",
      height: scrollHidden ? "100vh" : "auto",
      overflowY: scrollHidden ? "hidden" : "visible",
      paddingBottom: "40px",
    }}
  >
    <img
      src={Logo}
      alt="logo"
      height={48}
      width={98}
      style={{ marginTop: "32px" }}
    />
    {children}
  </Container>
);

export default LoginLayout;
