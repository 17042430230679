const baseAPI = import.meta.env.VITE_API_URL;
const baseMonolith = import.meta.env.VITE_MONOLITH_URL;

const ApiConfig = {
  auth: {
    getOTP: baseAPI + "/api/v1/navigo/email/otp",
    verifyOTP: baseAPI + "/api/v1/navigo/email/verification",
    getPhoneOTP: baseMonolith + "/login/v2/otp",
    verifyPhoneOTP: baseMonolith + "/login/v5/otp",
    verifyPhoneOTPGetProfiles: baseMonolith + "/login/v5/otp/profile",
    register: baseMonolith + "/login/v3/register",
    registerSource: baseMonolith + "/login/sourceregister",
    regenToken: baseMonolith + "/login/token",
    setPatientId: baseAPI + "/api/v1/navigo/email/setPatientID",
    chatGet: baseAPI + "/v2/chat/users/%d",
    chatPost: baseAPI + "/v2/chat/users",
    getTenant:baseMonolith+"/login/v1/tenantid",
  },
  services: {
    getServices: baseAPI + "/api/v1/navigo/service/client",
    getServicesByPatientID: baseAPI + "/api/v2/navigo/service/patient/%d",
    getService: baseAPI + "/api/v1/navigo/service/",
    getServiceByPatientID: baseAPI + "/api/v2/navigo/service/%d/patient/%p",
    getSlots: baseAPI + "/api/v1/navigo/appointment/available-slots",
    getSlotsV2: baseAPI + "/api/v2/navigo/appointment/available-slots",
  },
  appointment: {
    createAppointment: baseAPI + "/api/v2/navigo/appointment",
    getAppointments: baseAPI + "/api/v1/navigo/appointment/patient/",
    getAppointment: baseAPI + "/api/v1/navigo/appointment/",
    cancelAppointment: baseAPI + "/api/v1/navigo/appointment/status",
    reschedule: baseAPI + "/api/v1/navigo/appointment/reschedule",
    createOrderId: baseAPI + "/api/v1/navigo/service/generatepaymentid",
    getStatus: baseAPI + "/api/v2/navigo/appointment/status/",
    getDocuments: baseAPI + "/api/v1/navigo/appointment/document/"
  },
  labtest: {
    getSlots: baseAPI + "/v1/thyro/getslots/%d",
    bookSlot: baseAPI + "/v1/thyro/booking",
    checkAvailibility: baseAPI + "/v1/ms/booking/service/check",
  },
  labtestRc: {
    getSlots: baseAPI + "/v1/rc/slots",
    bookSlot: baseAPI + "/v1/rc/booking",
  },
  documents: {
    generateToken: baseMonolith + "/documents/token/generate",
  },
  patient: {
    update: baseMonolith + "/patients/{0}/profile",
    profile: baseAPI + "/v1/patients/%d",
  },
  family: {
    getAllRelations: baseAPI + "/v1/family/relations",
    getOTP: baseAPI + "/v1/family/otp/generate",
    verifyOTP: baseAPI + "/v1/family/otp/validate",
    createMember: baseAPI + "/v1/family",
    createMemberMinor: baseAPI + "/v1/family/minor",
    getMembers: baseAPI + "/v1/family",
  },
  address: {
    getAll: baseAPI + "/api/v1/address",
    save: baseAPI + "/api/v1/address",
    update: baseAPI + "/api/v1/address/%d",
    delete: baseAPI + "/api/v1/address/%d",
    getPincodeDetails: baseAPI + "/api/v1/address/pincode/%d",
  },
  authSSO:{
    getRedirectUrl:baseAPI+"/cd/v1/auth/create",
    autoLogin:baseMonolith,
    check:baseAPI+"/cd/v1/auth/checkemail",
  }
};

export default ApiConfig;
